/* 
 * @Author: 曹俊杰 
 * @Date: 2020-12-10 15:18:17
 * @Module:上传图片 
 */
 <template>
  <div class="addImage">
    <el-upload :action="action"
               :data="dataObj"
               :show-file-list="false"
               :multiple="false"
               :on-success="success"
               accept="image/*"
               :before-upload="upload">
      <i class="el-icon-picture-outline hover"></i>
    </el-upload>
  </div>
</template>
 <script>
import { Loading } from 'element-ui';
export default {
  components: {},
  data () {
    return {
      action: '',
      dataObj: {
        success_action_status: '200',
      },
      loading: null,
      path: ""
    };
  },
  created () {
    this._getAliyun()
  },
  mounted () { },
  methods: {
    async _getAliyun () {
      const { data: {
        policy,
        signature,
        accessid,
        host,
        dir
      } } = await this.$api.getAliyun({ type: 'image' })
      this.action = host
      Object.assign(this.dataObj, {
        policy,
        signature,
        dir,
        OSSAccessKeyId: accessid
      })
    },
    getFileName (filename, imageSize) {    // 设置文件名字
      let getSuffix = (filename) => {
        let pos = filename.lastIndexOf('.');
        let suffix = '';
        if (pos != -1) {
          suffix = filename.substring(pos);
        }
        console.log(suffix)
        return suffix;
      };
      return (
        new Date().getTime() +
        Math.random()
          .toString(36)
          .substring(3, 20) + `_width_${imageSize.width}_height_${imageSize.height}_` +
        getSuffix(filename)
      );
    },
    async upload (file) {  //文件上传前的回调
      const imageSize = await this.$imageSize(file)
      const filename = this.getFileName(file.name, imageSize)
      const copyFile = new File([file], `${filename}`)
      Object.assign(this.dataObj, {
        key: `image/${filename}`
      })
      // this.uidObject[file.uid] = `image/${filename}`
      this.loading = Loading.service({ fullscreen: true });
      this.path = `${this.action}${this.dataObj.dir}${filename}`
      return copyFile
    },
    success () {
      this.$emit("on-success", this.path)
      this.loading.close();
    },
  },
};
 </script>
 <style lang='scss' scoped>
.addImage {
  // background: #f66;
}
</style>