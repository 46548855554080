<template>
  <div class="item"
       :style="{width:width+'px',height:height+'px'}"
       :class="{active:active }">
    <el-avatar :size="34"
               :src="item.avatar"></el-avatar>
    <div class="username">{{item.username}}</div>
    <slot></slot>

  </div>
  <!-- <div>111</div> -->
</template>
<script>
export default {
  components: {},
  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => ({})
    },
    height: {
      type: String,
      default: "50",
    },
    width: {
      type: String,
      default: "300"
    }
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
</script>
<style lang='scss' scoped>
.item {
  // height: 50px;
  display: flex;
  align-items: center;
  // width: 300px;
  padding-left: 10px;
  cursor: pointer;
  .username {
    color: #262626;
    font-size: 14px;
    margin-left: 10px;
    width: 220px;
  }
  .iconfont {
    display: none;
  }
}
.active {
  background: #ffecec;
  border-radius: 4px;
  .iconfont {
    display: block;
  }
}
</style>