/* 
 * @Author: 曹俊杰 
 * @Date: 2021-03-08 15:58:26
 * @Module: 用户单选
 */
 <template>
  <div class="userRadiobox">
    <el-dialog :title="title"
               center
               :visible.sync="dialogVisible"
               width="440px">
      <div class="userRadiobox-center">
        <div class="userRadiobox-center-search">
          <el-input placeholder="请输入内容"
                    size="mini"
                    prefix-icon="el-icon-search"
                    v-model="input2">
          </el-input>
        </div>
        <div class="userRadiobox-center-list">
          <userItem v-for="(item,index) in list"
                    :item="item"
                    width="420"
                    height="50"
                    textWidth="340"
                    :active="isActive(item)"
                    @click.native="()=>itemClick(item)"
                    :key="index">
            <div class="iconfont iconxuanzhong"
                 style="color:#DF2A29"></div>
          </userItem>
        </div>
        <div slot="footer"
             class="dialog-footer">
          <el-button size="small"
                     @click="dialogVisible = false">取 消</el-button>
          <el-button size="small"
                     type="primary"
                     @click="dialogVisible = false">确 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
 <script>
import userItem from "@/views/homepage/chat/module/userCheckbox/userItem"
export default {
  components: {
    userItem
  },
  props: {
    title: {
      type: String,
      default: "提示"
    }
  },
  data () {
    return {
      dialogVisible: false,
      input2: "",
      list: [
        {
          username: "安特卫普",
          avatar: "https://oss.mcn-open.com/images/AFfWluPvmlUhpEeStMyNrmlATsxmgcD3hIgTQ1Jd.jpeg",
          uuid: 1
        },
        {
          username: "安特卫普",
          avatar: "https://oss.mcn-open.com/images/AFfWluPvmlUhpEeStMyNrmlATsxmgcD3hIgTQ1Jd.jpeg",
          uuid: 2
        },
        {
          username: "安特卫普",
          avatar: "https://oss.mcn-open.com/images/AFfWluPvmlUhpEeStMyNrmlATsxmgcD3hIgTQ1Jd.jpeg",
          uuid: 3
        },
        {
          username: "安特卫普",
          avatar: "https://oss.mcn-open.com/images/AFfWluPvmlUhpEeStMyNrmlATsxmgcD3hIgTQ1Jd.jpeg",
          uuid: 4
        },
        {
          username: "安特卫普",
          avatar: "https://oss.mcn-open.com/images/AFfWluPvmlUhpEeStMyNrmlATsxmgcD3hIgTQ1Jd.jpeg",
          uuid: 5
        },
        {
          username: "安特卫普",
          avatar: "https://oss.mcn-open.com/images/AFfWluPvmlUhpEeStMyNrmlATsxmgcD3hIgTQ1Jd.jpeg",
          uuid: 6
        },
        {
          username: "安特卫普",
          avatar: "https://oss.mcn-open.com/images/AFfWluPvmlUhpEeStMyNrmlATsxmgcD3hIgTQ1Jd.jpeg",
          uuid: 7
        },
        {
          username: "安特卫普",
          avatar: "https://oss.mcn-open.com/images/AFfWluPvmlUhpEeStMyNrmlATsxmgcD3hIgTQ1Jd.jpeg",
          uuid: 8
        },
        {
          username: "安特卫普",
          avatar: "https://oss.mcn-open.com/images/AFfWluPvmlUhpEeStMyNrmlATsxmgcD3hIgTQ1Jd.jpeg",
          uuid: 9
        },
        {
          username: "安特卫普",
          avatar: "https://oss.mcn-open.com/images/AFfWluPvmlUhpEeStMyNrmlATsxmgcD3hIgTQ1Jd.jpeg",
          uuid: 10
        }
      ],
      activeList: []
    };
  },
  mounted () { },
  methods: {
    setDialogVisible (res) {
      this.dialogVisible = res
    },
    isActive (item) {
      return this.activeList.includes(item.uuid)
    },
    itemClick ({ uuid }) {
      if (this.activeList.includes(uuid)) {
        this.deleteItem(uuid)
      } else {
        this.addItem(uuid)
      }
    },
    // 删除指定item
    deleteItem (uuid) {
      this.activeList.splice(this.activeList.indexOf(uuid), 1)
    },
    addItem (uuid) {
      this.activeList = [uuid]
    }
  },
};
 </script>
 <style lang='scss' scoped>
.userRadiobox {
  & /deep/ .el-dialog__body {
    padding: 0px;
  }
  &-center {
    &-search {
      height: 60px;
      padding: 0 20px;
      display: flex;
      align-items: center;
    }
    &-list {
      padding: 0 10px;
      & /deep/ .username {
        width: 340px;
      }
      height: 430px;
      overflow: auto;
    }
    .dialog-footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
    }
  }
}
</style>